import logo from "../assets/img/logo.png"
// import iseaLogo from "../assets/img/isea.png"
// import iseaLogo from "../assets/img/ctf.png"
import ctfLogo from "../assets/img/ctf.png"
import cdacLogo from "../assets/img/cdac.png";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import Services from "../Service/Service";
import UserUtil from "../UserUtil/UserUtil";
import { API_BASE_URL, IVP_BASE_URL } from "../config/config";
import mietyLogo from "../assets/img/miety.png";
import Avatar from '../assets/img/user-profile.png'
import Reward from "../assets/img/awards.gif";
import CountUp from 'react-countup';
import { IVP_REDIRECT_URL, IVP_REDIRECT_URL_DASHBOARD } from "../config/config";

const Header = ({ currentSection }) => {
    let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);
    const [redirected, setRedirected] = useState(false);
    const [getRewardPoints, setRewardPoints] = useState(0);
    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn")
    let username = "";
    let roles = "";
    let user_id = "";
    if (LoggedIn == "true") {
        const token = UserUtil.getSessionStorage("_txyz")
        username = UserUtil.getUsernameFromToken(token);
        roles = UserUtil.getRolesFromToken(token);
        user_id = UserUtil.getSessionStorage("__ivpflag");
    }


    const logoutHandler = (event) => {
        event.preventDefault();
        // sessionStorage.removeItem("token");
        // sessionStorage.removeItem("LoggedIn");
        // navigate("/");

        UserUtil.logout();
    }


    const dashBoardHandler = (event) => {
        event.preventDefault();
        navigate("/admin-dashboard/ctf");
    }

    const dashBoardUserHandler = (event) => {
        event.preventDefault();
        navigate("/user-dashboard/ctf");
    }

    useEffect(() => {
        function handleClickOutside(event) {
            // Check if the clicked element matches '.mobile-nav-toggle' selector
            if (event.target.matches('.mobile-nav-toggle')) {
                // Toggle classes on '#navbar' element and 'bi-list'/'bi-x' classes on the clicked element
                const navbar = document.querySelector('#navbar');
                navbar.classList.toggle('navbar-mobile');
                event.target.classList.toggle('bi-list');
                event.target.classList.toggle('bi-x');
            }
        }

        function handleDropdownClick(event) {
            // Check if '#navbar' has 'navbar-mobile' class and the clicked element matches '.navbar .dropdown > a' selector
            const navbar = document.querySelector('#navbar');
            if (navbar.classList.contains('navbar-mobile') && event.target.matches('.navbar .dropdown > a')) {
                event.preventDefault();
                // Toggle 'dropdown-active' class on the next sibling of the clicked element
                event.target.nextElementSibling.classList.toggle('dropdown-active');
            }
        }

        // Add event listeners on component mount
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('click', handleDropdownClick, true);

        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleDropdownClick, true);
        };
    }, []);

    // useEffect(() => {
    //     // Check if currentSection is "quiz" and navigate accordingly
    //     if (currentSection === 'quiz') {
    //         navigate('/dashboard/quiz-dashboard');
    //     }
    // }, [currentSection]);
    const handleLoginRedirect = () => {
        // window.open(IVP_BASE_URL+'/loginRedirect?client_id=ctf', '_blank');
        // setRedirected(true)
        //  window.location.href = `${IVP_BASE_URL}/loginRedirect?client_id=ctf`;
        window.location.href = `${IVP_REDIRECT_URL}`;
    };

    // useEffect(() => {

    //     const data = {userid : user_id}
    //      Services.IVPRewardpoints(data)
    //      .then(async response => {
    //          console.log("Data getting rewards",response.data)
    //          const quizAppData = response.data.data.applications_data.find(app => app.app_id === "ctf");
    //          console.log("q",quizAppData);
    //          const quizRewardPoints = quizAppData ? quizAppData.total_reward_points : 0;
    //          console.log("p",quizRewardPoints);
    //          setRewardPoints(quizRewardPoints);
    //      }).catch(err => {

    //      })

    //  }, []);
    const handleLoginRedirectIvp = () => {
        window.open(IVP_REDIRECT_URL_DASHBOARD);

    };


    return (
        <div>
            <header id="header" className="header fixed-top header-scrolled">
                <div className="container-fluid container-xl d-flex align-items-center">
                    <div>
                        <a href="https://www.meity.gov.in/" className="logo d-flex align-items-center" target="_blank" title="MeitY (External website that opens in new window)">
                            <img
                                src={mietyLogo}
                                alt="logo"
                                id="logo"
                                height={70}
                                width={155}
                            />
                        </a>
                    </div>
                    <span className="border-header"></span>
                    <div>
                        <Link to="/" className="logo d-flex align-items-center" title="Capture The Flag">
                            <img
                                src={ctfLogo}
                                alt="logo"
                                id="logo"
                                height={70}
                                width={155}
                            />
                        </Link>
                    </div>
                    {/* <div>
                        <a href="https://www.cdac.in/" className="logo d-flex align-items-center" target="_blank" title="C-DAC">
                            <img
                                src={cdacLogo}
                                alt="logo"
                                id="logo"
                                height={90}
                                width={80}
                            />
                        </a>
                    </div> */}
                    <div className="ms-auto">
                        <nav id="navbar" className="navbar">
                            {/* <div> */}

                            <ul>
                                {roles.includes("ROLE_ADMIN") ? (
                                    <>
                                        {currentSection === 'quiz' && (
                                            <>
                                                <li className="dropdown">
                                                    <a href="#">
                                                        <span>Quiz</span> <i className="bi bi-chevron-down" />
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <Link to="/dashboard/quiz/create-quiz">
                                                                <a href="#">Create Quiz</a>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/dashboard/quiz/total-quizzes">
                                                                <a href="#">Quiz List</a>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dashboard/quiz/quiz-statistics">
                                                                <a href="#">Quiz Statistics</a>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="dropdown">
                                                    <a href="#">
                                                        <span>Question</span> <i className="bi bi-chevron-down" />
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <Link to="/dashboard/quiz/create-questions">
                                                                <a href="#">Create Question</a>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/dashboard/quiz/total-questions">
                                                                <a href="#">Questions List</a>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/dashboard/quiz/certificate">
                                                        <a className="nav-link p-0" href="#">Certificate</a>
                                                    </Link>
                                                </li>
                                            </>

                                        )}

                                        {currentSection === 'ctf' && (
                                            <>
                                                {/* <li>
                                                    <Link to="/admin-dashboard/ctf/admin-video-tutorial" className="nav-link">
                                                        <i class="ri-vidicon-fill"></i>&nbsp;Video Tutorial
                                                    </Link>
                                                </li> */}
                                                <li className="dropdown">
                                                    <a href="#">
                                                        <span>Challenge</span> <i className="bi bi-chevron-down" />
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <Link to="/admin-dashboard/ctf/create-challenge">
                                                                <a href="#">Create Challenge</a>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/admin-dashboard/ctf/view-challenges">
                                                                <a href="#">Challenge List</a>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="dropdown">
                                                    <a href="#">
                                                        <span>CTF</span> <i className="bi bi-chevron-down" />
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <Link to="/admin-dashboard/ctf/create-ctf-list">

                                                                <a href="#">Create CTF</a>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="/admin-dashboard/ctf/view-ctf-list" >
                                                                <a href="#">CTF List</a>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/admin-dashboard/ctf/leaderboard" className="nav-link">
                                                        Leaderboard
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                <Link to="/admin-dashboard/ctf/users" className="nav-link">
                                                   Users
                                                </Link>
                                            </li> */}
                                            <li>
                                            <button className="getstarted ml-3 border-0 fw-bold" onClick={handleLoginRedirectIvp}>
                                                My IVP Dashboard
                                            </button>
                                            </li>
                                            </>

                                        )}
                                        {/* Add the rest of your menu items here */}
                                    </>
                                ) : roles.includes("ROLE_USER") ? (
                                    <>
                                        {/* <Link to="/" className="nav-link">
                                        <i className="fa fa-fw fa-home" aria-hidden="true"></i>Home
                                    </Link> */}

                                        {/* <li>
                                            <Link to="/user-dashboard/user-video-tutorial" className="nav-link">
                                                <i class="ri-vidicon-fill"></i>&nbsp;Video Tutorial
                                            </Link>
                                        </li> */}
                                        <li className="mx-3">
                                            <Link to="/user-dashboard/user-scoreboard" className="nav-link">
                                                Scoreboard
                                            </Link>
                                        </li>
                                        <li>
                                        <button className="getstarted ml-3 border-0 fw-bold" onClick={handleLoginRedirectIvp}>
                                                My IVP Dashboard
                                            </button>
                                        </li>
                                        {/* <li className="mx-3">
                                        <button
                                            className="btn-rewards d-inline-flex border-0 align-items-center justify-content-center align-self-center"
                                        >
                                            <span className="fw-bold p-2"><CountUp end={getRewardPoints} separator=""/></span>
                                            <img src={Reward} height={45} />
                                        </button>
                                        </li> */}
                                    </>
                                ) : null}


                                {!LoggedIn ?
                                    <>
                                        {/* <li>
                                            <Link to="/signup">
                                                <a className="getstarted m-0" href="#about">
                                                    Sign Up
                                                </a>
                                            </Link>
                                        </li> */}
                                        <li>
                                            {/* <Link to="/login"> */}
                                            <button className="getstarted ml-3 border-0 fw-bold" onClick={handleLoginRedirect}>
                                                Login
                                            </button>
                                            {/* </Link> */}
                                        </li>
                                    </>
                                    :
                                    <li className="dropdown">

                                        <div className="dash">
                                            <img
                                                src={Avatar}
                                                alt="logo"
                                                id="logo"
                                                height={40}
                                                width={40}
                                            /><span className="text-capitalize m-1">{username}</span> <i className="bi bi-chevron-down" />
                                        </div>

                                        <ul>
                                            {roles === "[ROLE_ADMIN]" ?
                                                <>
                                                    <li>
                                                        <a href="#" onClick={dashBoardHandler}>Dashboard</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="#" onClick={handleLoginRedirectIvp}>My IVP Dashboard</a>
                                                    </li> */}
                                                </>
                                                :
                                                <>
                                                    <li>
                                                        <a href="#" onClick={dashBoardUserHandler}>Dashboard</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="#" onClick={handleLoginRedirectIvp}>My IVP Dashboard</a>
                                                    </li> */}
                                                </>
                                            }

                                            <li>
                                                <a href="#" onClick={logoutHandler}>Logout</a>
                                            </li>


                                        </ul>

                                    </li>

                                }
                            </ul>
                            <div>
                                <i className="bi bi-list mobile-nav-toggle" />
                            </div>
                            {/* </div> */}
                        </nav>
                    </div>
                </div>
            </header >
            {/* End Header */}
        </div >
    )
}

export default Header;