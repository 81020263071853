import Services from '../../src/Service/Service';
import { useState, useEffect, CSSProperties } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import jwtDecode from 'jwt-decode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import swal from "sweetalert"
import UserUtil from '../UserUtil/UserUtil'
import BasicDetailsModal from '../BasicDetailsModal/BasicDetailsModal';


const Ivp = () => {
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#ffffff");
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    let navigate = useNavigate();

    const secureFlag =
        window.location.protocol === "https:"
            ? { secure: true, sameSite: "strict" }
            : { secure: false, sameSite: "lax" };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        // const state = urlParams.get('state');
        console.log("code", code);
        const body = { code, client_id: "ctf" }

        let formData = new FormData();
        formData.append("code", code);
        // formData.append("state", state);
        Services.IvpNewUser(formData)
            .then(response => {
                setTimeout(() => {
                    if (response.status === 200) {
                        UserUtil.setSessionStorage("__ivpflag", 1);
                        UserUtil.setCookies("_txyz", response.data.id, { expires: 1, ...secureFlag });
                        UserUtil.setSessionStorage("__xyzabc", response.data.user_id);
                        UserUtil.setSessionStorage("IsLoggedIn", true);

                        const flag = UserUtil.getSessionStorage("flag");
                        const token = UserUtil.getSessionStorage("_txyz");

                        const roles = getRolesFromToken(token);


                        function getUsernameFromToken(token) {
                            // Decode the JWT token
                            const decodedToken = jwtDecode(token);

                            // Extract the username from the decoded token
                            const username = decodedToken ? decodedToken.sub : null;

                            return username;
                        }

                        function getRolesFromToken(token) {
                            // Decode the JWT token
                            const decodedToken = jwtDecode(token);

                            // Extract the roles from the decoded token
                            const roles = decodedToken ? decodedToken.roles : null;

                            return roles;
                        }

                        if (roles === '[ROLE_ADMIN]') {
                            navigate('/admin-dashboard/ctf');
                            window.location.reload();
                        } else if (roles === '[ROLE_USER]') {
                            navigate(`/user-dashboard/ctf`);
                            window.location.reload();
                        }

                    }
                    setIsLoading(false);
                }, 2000);
            })
            .catch(err => {
                if (err.response && err.response.status === 400) {
                    setIsLoading(false);
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");

                }else  if (err.response && err.response.status === 404) {
                    let user_id = err.response.data.msg;
                    console.log("userid",user_id);
                    UserUtil.setSessionStorage("__xyzabc",user_id);
                    setIsLoading(false);
                    UserUtil.setSessionStorage("__utp", true);
                    setShowModal(true);
                }
            });







        //     Services.Ivpauthorize(body).then(res => {
        //         console.log("res : ", res.data)
        //         if (res.data.status === 1) {

        //             let access_token = res.data.data.data.access_token;
        //             let refresh_token = res.data.data.data.refresh_token;   

        //             const data = {}
        //             // console.log("ghgh", token);
        //             Services.Userinfo(access_token,refresh_token)
        //                 .then(response => {
        //                     if (response.status === 200) {
        //                         console.log("userinfo",response.data.data);
        //                         let bodyjson = { email: response.data.data.email, name: response.data.data.username,unique_id:response.data.data.unique_id,firstName:response.data.data.first_name,lastName:response.data.data.last_name,role:response.data.data.role,user_id:response.data.data.user_id };
        //                         UserUtil.setSessionStorage("__xyzabc",response.data.data.user_id);
        //                         UserUtil.setSessionStorage("__ivpflag",1);
        //                         Services.IvpLogin(bodyjson)
        //                             .then(response => {
        //                                 setTimeout(() => {
        //                                 if (response.status === 200) {
        //                                 //    console.log("token",response.data.id);
        //                                 UserUtil.setCookies("_txyz",response.data.id, { expires: 1, ...secureFlag });
        //                                 UserUtil.setSessionStorage("IsLoggedIn",true);
        //                                 // const flag = sessionStorage.getItem("flag");
        //                                 // const token = sessionStorage.getItem("token");
        //                                 const flag = UserUtil.getSessionStorage("flag");
        //                                 const token =UserUtil.getSessionStorage("_txyz");
        //                                     const username = getUsernameFromToken(token);
        //                                     const roles = getRolesFromToken(token);

        //                                     function getUsernameFromToken(token) {
        //                                         // Decode the JWT token
        //                                         const decodedToken = jwtDecode(token);

        //                                         // Extract the username from the decoded token
        //                                         const username = decodedToken ? decodedToken.sub : null;

        //                                         return username;
        //                                     }

        //                                     function getRolesFromToken(token) {
        //                                         // Decode the JWT token
        //                                         const decodedToken = jwtDecode(token);

        //                                         // Extract the roles from the decoded token
        //                                         const roles = decodedToken ? decodedToken.roles : null;

        //                                         return roles;
        //                                     }

        //                                     if (roles === '[ROLE_ADMIN]') {
        //                                         navigate('/admin-dashboard/ctf');
        //                                         window.location.reload();
        //                                     } else if (roles === '[ROLE_USER]') {
        //                                         navigate(`/user-dashboard/ctf`);
        //                                          window.location.reload();
        //                                     } 

        //                             } 
        //                             setIsLoading(false);
        //                         }, 2000);
        //                     }).catch(err => {
        //                         if (err.response && err.response.status === 401) {
        //                             swal("Error", err.response.data.error, "error");
        //                         } else if (err.response && err.response.data && err.response.data.errors) {
        //                             let errorMessages = Object.values(err.response.data.errors).join('\n');
        //                             swal("Message!", errorMessages, "warning");                
        //                             //setFieldValue('captcha', '');
        //                         } else if (err.response && err.response.data && err.response.data.msg) {
        //                             swal("Message!", err.response.data.msg, "warning");
        //                         } else {
        //                             swal("Message!", "Server error occurred", "warning");
        //                         }
        //                     })
        //         }

        //     }).catch(err => {
        //         if (err.response && err.response.data && err.response.data.status === 0) {
        //             swal("Message!", err.response.data.errors, "warning");
        //         } else {
        //             swal("Message!", "Server error occurred", "warning");
        //         }
        //     })

        // }
        //     }).catch(err => {
        //         if (err.response && err.response.data && err.response.data.status === 0) {
        //             swal("Message!", err.response.data.errors, "warning");
        //         } else {
        //             swal("Message!", "Server error occurred", "warning");
        //         }
        //     })

    }, []);

    return (
        isLoading ? <Spinner /> :
            <div>
              { showModal && <BasicDetailsModal show={showModal} handleClose={handleClose} />}
            </div>
    )
}

export default Ivp;