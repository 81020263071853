import '../assets/Style.css'
import '../assets/css/style.css'
import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jwtDecode from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import certificate from '../assets/img/certificate.png'
import SideMenu from '../SideMenu/SideMenu';
import AttemptedChallengesByUser from '../AttemptedChallengesByUser/AttemptedChallengesByUser';
import UserCtfStatistics from '../UserCtfStatistics/UserCtfStatistics';
import UserUtil from '../UserUtil/UserUtil';
import CountUp from 'react-countup';
import Reward from "../assets/img/awards.gif";

const UserCTFDashboard = () => {
    let navigate = useNavigate();
    const [selectedChallengeList, setSelectedChallengeList] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Challenge');
    const [flagValue, setFlagValue] = useState('');
    const [getChallengeId, setChallengeId] = useState(null);
    const [challengeLists, setChallengeLists] = useState([]);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selectedHint, setSelectedHint] = useState(null);
    const [clickedChallenge, setClickedChallenge] = useState(null);
    const [attempts, setAttempts] = useState(0);
    const [breadcrumbText, setBreadcrumbText] = useState('');
    const [headingText, setHeadingText] = useState('Dashboard');
    const [displayChallengeLists, setDisplayChallengeLists] = useState(true);
    const [getRewardPoints, setRewardPoints] = useState(0);
    const [listRewardResponse, setlistRewardResponse] = useState([]);

    const handleClose1 = () => {
        setShow1(false);

    }

    const columnsrewards = [
        { name: 'Sr No', selector: (row, index) => index + 1, sortable: true, center: true, wrap: true, compact: true, maxWidth: '200px' },
        { name: 'Activity', selector: (row) => row.activity, sortable: true, center: true, wrap: true, compact: true, maxWidth: '1000px' },
        { name: 'Reward Points', selector: (row) => row.reward_point, sortable: true, center: true },
        { name: 'Timestamp', selector: (row) => row.timestamp, sortable: true, center: true },
    ];


    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',

                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius: '5px'
            },
        },
    };



    const handleShow1 = () => {
        setShow1(true);
        const data = { userid: user_id, client_id: "ctf" }
        Services.IVPRewardpointsDetails(data)
            .then(async response => {
                console.log("details", response.data.data);
                setlistRewardResponse(response.data.data);
            })
            .catch(err => {
                console.error("Error fetching reward points", err);
            });
    };

    const [statistics, setStatistics] = useState({
        attendedQuizzes: 0,
        certificatesAchieved: 0,
        unattemptedQuizzes: 0,
        quizzesAssigned: 0,
    });





    // const handleChallengeListClick = (challengeList) => {
    //     setSelectedChallengeList(challengeList);
    //      navigate("")
    // };



    // const LoggedIn = sessionStorage.getItem("LoggedIn");
    // let email = "";
    // let username = "";
    // let roles = "";

    // if (LoggedIn === "true") {
    //     const token = sessionStorage.getItem("token");
    //     email = getEmailFromToken(token);
    //     username = getUsernameFromToken(token);
       
    //     function getEmailFromToken(token) {

    //         const decodedToken = jwtDecode(token);

    //         const email = decodedToken ? decodedToken.sub : null;

    //         return email;
    //     }


    //     function getUsernameFromToken(token) {

    //         const decodedToken = jwtDecode(token);

    //         const username = decodedToken ? decodedToken.username : null;

    //         return username;
    //     }
        

    //     roles = UserUtil.getRolesFromToken(token);
    // }

    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn");
    let email = "";
    let username = "";
    let roles = "";
    let user_id = "";

    if (LoggedIn === "true") {
        // const token = sessionStorage.getItem("token");
        const token = UserUtil.getSessionStorage("_txyz");
        email = getEmailFromToken(token);
        username = getUsernameFromToken(token);

        function getEmailFromToken(token) {

            const decodedToken = jwtDecode(token);

            const email = decodedToken ? decodedToken.sub : null;

            return email;
        }


        function getUsernameFromToken(token) {

            const decodedToken = jwtDecode(token);

            const username = decodedToken ? decodedToken.username : null;

            return username;
        }

        roles = UserUtil.getRolesFromToken(token);
        user_id = UserUtil.getSessionStorage("__xyzabc")

    }





    useEffect(() => {
        $(document).ready(function () {
            $(".btn-toggle-menu").click(function () {
                console.log("Button clicked"); // Debugging line
                $("#wrapper").toggleClass("toggled");
            });
        });
    }, []);
    
   

    const getRoleDisplayText = (roles) => {
        if (roles.includes('ROLE_ADMIN')) {
            return 'instructor';
        }
        if (roles.includes('ROLE_INSTRUCTOR')) {
            return 'instructor';
        }
        if (roles.includes('ROLE_USER')) {
            return 'user';
        }
        return roles.join(', ');
    };


   
    useEffect(() => {

        const data = { userid: user_id }
        Services.IVPRewardpoints(data)
            .then(async response => {
                console.log("Data getting rewards", response.data)
                const quizAppData = response.data.data.applications_data.find(app => app.app_id === "ctf");
                console.log("q", quizAppData);
                const quizRewardPoints = quizAppData ? quizAppData.total_reward_points : 0;
                console.log("p", quizRewardPoints);
                setRewardPoints(quizRewardPoints);
            }).catch(err => {

            })

    }, []);


    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container  mt-4">

                    <ol>
                        <li>
                        <Link to="/">
                            <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        {/* <li aria-current="page">
                            <Link to="/quiz/user-dashboard">
                                Dashboard
                            </Link>
                        </li> */}

                        {/* <li>
                            <Link to="/quiz/ctf-dashboard">
                              User Dashboard
                            </Link>
                        </li> */}



                    </ol>
                    <h2 className="text-capitalize">Welcome, {username}</h2>
                    <p>You are logged in as {getRoleDisplayText(roles)}</p>

                </div>
            </section>
            <div className='container-fluid'>


                <div id="wrapper" className="wrapper-content">

                <SideMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />

                    <div id="page-content-wrapper">
                    <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header d-flex justify-content-between align-items-center w-100 py-3">
                                    <button
                                        className="btn-menu  btn-toggle-menu"
                                        type="button"
                                    >
                                        <i className="fa fa-bars" />
                                    </button>
                                    <button
                                        className="btn-rewards d-inline-flex border-0 align-items-center justify-content-center align-self-center"
                                        onClick={handleShow1}
                                    >
                                        <span className="fw-bold p-2">Rewards: <CountUp end={getRewardPoints} separator="" /></span>
                                        <img src={Reward} height={45} />
                                    </button>
                                </div>
                            </div>
                        </nav>

                        {selectedMenu === 'Dashboard' && <UserCtfStatistics selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>}
                        {selectedMenu === 'AttemptedChallenges' && <AttemptedChallengesByUser selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu}/>}

                    </div>
                </div>

            </div>
            <Modal size="xl" show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize text-danger'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={columnsrewards}
                        data={listRewardResponse}
                        striped
                        highlightOnHover
                        pagination
                        paginationPerPage={10}
                        noHeader
                        responsive
                        customStyles={customStyles}

                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserCTFDashboard